::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  
  ::-webkit-scrollbar-button {
    width: 0px !important;
    height: 0px !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #22957B !important;
    border-radius: 8px !important;
  }
  ::-webkit-scrollbar-track {
    background: #1E1E1E !important;
    border-radius: 28px !important;
  }

@tailwind base;
@tailwind components;
@tailwind utilities;